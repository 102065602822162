import React from 'react';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Button-close.css";



class ButtonClose extends React.Component {
    render() {
        return (
            <button onClick={this.props.onClick} data-dialog-close className={s.close + " " + ['action'] + " " + [this.props.className]}>
                <em className={s.left}>
                    <span className={s.line}></span>
                </em>
                <em className={s.right}>
                    <span className={s.line}></span>
                </em>
            </button>
        );
    }
}

export default withStyles(s)(ButtonClose);