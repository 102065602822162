exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2uOhs{-webkit-clip-path:url(#SVGID_2_);clip-path:url(#SVGID_2_);fill:url(#SVGID_3_)}._3OFac{fill:none;stroke:#fff;stroke-width:4.252;stroke-miterlimit:22.926}._ROlv{-webkit-clip-path:url(#SVGID_5_);clip-path:url(#SVGID_5_);fill:url(#SVGID_6_)}._1YX7H{-webkit-clip-path:url(#SVGID_8_);clip-path:url(#SVGID_8_);fill:url(#SVGID_9_)}._1O0Sj{-webkit-clip-path:url(#SVGID_11_);clip-path:url(#SVGID_11_);fill:url(#SVGID_12_)}._1OKYT{-webkit-clip-path:url(#SVGID_14_);clip-path:url(#SVGID_14_);fill:url(#SVGID_15_)}._3-Vd8{-webkit-clip-path:url(#SVGID_17_);clip-path:url(#SVGID_17_);fill:url(#SVGID_18_)}.Q_Gsu{-webkit-clip-path:url(#SVGID_20_);clip-path:url(#SVGID_20_);fill:url(#SVGID_21_)}._1O0Sj,._1OKYT,._1YX7H,._2uOhs,._3-Vd8,._3OFac,._ROlv,.Q_Gsu{-webkit-clip-path:#fff;clip-path:#fff;fill:#fff}", ""]);

// exports
exports.locals = {
	"st0": "_2uOhs",
	"st1": "_3OFac",
	"st2": "_ROlv",
	"st3": "_1YX7H",
	"st4": "_1O0Sj",
	"st5": "_1OKYT",
	"st6": "_3-Vd8",
	"st7": "Q_Gsu"
};