exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1NG5C{width:50px;height:50px;background:-webkit-gradient(linear,left top,right top,from(#ff5ca2),color-stop(#ef64b3),color-stop(#d86dc7),to(#a383f5));background:-webkit-linear-gradient(left,#ff5ca2,#ef64b3,#d86dc7,#a383f5);background:-o-linear-gradient(left,#ff5ca2,#ef64b3,#d86dc7,#a383f5);background:linear-gradient(90deg,#ff5ca2,#ef64b3,#d86dc7,#a383f5);position:fixed;bottom:5px;right:5px;border-radius:50%;border:none;cursor:pointer}._1NG5C:hover{opacity:1}.kaYPr{color:#fff;position:absolute;top:50%;left:50%;margin-top:-9px;margin-left:-5px}", ""]);

// exports
exports.locals = {
	"scroll": "_1NG5C",
	"arrow-up": "kaYPr"
};