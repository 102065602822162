import React from "react";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import {  LgScreen, LgScreenHidden  } from 'react-responsive-redux';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./Navigation.css";
import {  mainMenu  } from './Desktop/Scripts.js';

const messages = defineMessages({
    home: {
        id: 'navigation.home',
        defaultMessage: 'Home',
    },
    services: {
        id: 'navigation.services',
        defaultMessage: 'Home',
    },
    portfolio: {
        id: 'navigation.portfolio',
        defaultMessage: 'Home',
    },
    about: {
        id: 'navigation.about',
        defaultMessage: 'Home',
    },
    contacts: {
        id: 'navigation.contacts',
        defaultMessage: 'Home',
    }
});



class Navigation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: this.props.DATANAVIGATION
        }
    }
    componentDidMount() {
        mainMenu();
    }
    render() {
        let contentDesctop = this.state.data.map((item, index) => {
                return (
                    <li key={index}>
                        <a className={s.link} href={item.href}>
                            <span className={s['overline-opening']}>
                                <FormattedMessage {...messages[item.intelId]} />
                            </span>
                        </a>
                    </li>
                        )
                    });
        let contentModile = this.state.data.map((item, index) => {
                return (
                <a key={index} className={s['global-menu__item'] + " " + ['global-menu__item'] + " " + s['global-menu__itemMenu'] + " " + ['global-menu__itemMenu']} href={item.hrefMobile}><FormattedMessage {...messages[item.intelId]} /></a>
                )
            });
        return (
            <div>
            <nav id={s['primaryNavWrap']} className={s['top-menu-links']} role="navigation">

                    <LgScreen>
                        <ul id="mainManu" className={s.links}>
                            {contentDesctop}
                        </ul>
                    </LgScreen>

            </nav>
                <div id="mobileMenu" className={s.mobileMenu}>
                <LgScreenHidden>
                <div className={s.content}>
                <div className={s.hamburger + " " + ['hamburger']}>
                <div className={s['hamburger__line'] + " " + s['hamburger__line--01']}>
                    <div className={s['hamburger__line-in'] + " " + s['hamburger__line-in--01'] + " " + ['hamburger__line-in'] + " " + ['hamburger__line-in--01']}></div>
                    </div>
                    <div className={s['hamburger__line'] + " " + s['hamburger__line--02']}>
                    <div className={s['hamburger__line-in'] + " " + s['hamburger__line-in--02'] + " " + ['hamburger__line-in'] + " " + ['hamburger__line-in--02']}></div>
                    </div>
                    <div className={s['hamburger__line'] + " " + s['hamburger__line--03']}>
                    <div className={s['hamburger__line-in'] + " " + s['hamburger__line-in--03'] + " " + ['hamburger__line-in'] + " " + ['hamburger__line-in--03']}></div>
                    </div>
                    <div className={s['hamburger__line'] + " " + s['hamburger__line--cross01']}>
                    <div className={s['hamburger__line-in'] + " " + s['hamburger__line-in--cross01']}></div>
                    </div>
                    <div className={s['hamburger__line'] + " " + s['hamburger__line--cross02'] + " " +  ['hamburger__line'] + " " + ['hamburger__line--cross02']}>
                    <div className={s['hamburger__line-in'] + " " + s['hamburger__line-in--cross02'] + " " + ['hamburger__line-in'] + " " + ['hamburger__line-in--cross02']}></div>
                    </div>
                    </div>
                    <div className={s['global-menu']}>
                    <div className={s['global-menu__wrap']}>
                    { contentModile }
                </div>
                </div>
                <svg className={s['shape-overlays'] + " " + ['shape-overlays']} viewBox="0 0 100 100" preserveAspectRatio="none">
                    <defs>
                    <linearGradient id="gradient1" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%"   stopColor="#00c99b"/>
                    <stop offset="100%" stopColor="#ff0ea1"/>
                    </linearGradient>
                    <linearGradient id="gradient2" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%"   stopColor="#ffd392"/>
                    <stop offset="100%" stopColor="#ff3898"/>
                    </linearGradient>
                    <linearGradient id="gradient3" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%"   stopColor="#110046"/>
                    <stop offset="100%" stopColor="#32004a"/>
                    </linearGradient>
                    </defs>
                    <path className={s['shape-overlays__path']}></path>
                    <path className={s['shape-overlays__path']}></path>
                    <path className={s['shape-overlays__path']}></path>
                    </svg>
                    </div>
                    </LgScreenHidden>
                </div>
            </div>
    );
    }
}


export default injectIntl(
    compose(
        withStyles(s),
        connect(
            state => ({
            DATANAVIGATION: state.dataRu.NAVIGATION
        }),
        dispatch => ({})
)
)(Navigation));
