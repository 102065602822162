import React from 'react';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from '../Inputs.css';
class Input extends React.Component {
    render() {
        return (
            <span className={s['input'] + " " + s['input--kozakura'] + " " + [this.props.inputClass] + " " + [(this.props.value == '' ? "" : "input--filled")]}>
                <input ref={this.props.inputRef} onChange={this.props.onChange} value={this.props.value} id={[this.props.inputId]} className={s['input__field'] + " " + ['input__field'] + " " + s['input__field--kozakura'] + " " + ['input__field--kozakura']} type={this.props.type}  required />
                <label className={s['input__label'] + " " + s['input__label--kozakura'] + " " + ['input__label--kozakura']} for="userName">
                    <span className="input__label-content input__label-content--kozakura" data-content="userName">{this.props.inputText}</span>
                </label>
                <svg className={s['graphic'] + " " + s['graphic--kozakura']} width="300%" height="100%" viewBox="0 0 1200 60" preserveAspectRatio="none">
                    <path fill="#fff" d="M1200,9c0,0-305.005,0-401.001,0C733,9,675.327,4.969,598,4.969C514.994,4.969,449.336,9,400.333,9C299.666,9,0,9,0,9v43c0,0,299.666,0,400.333,0c49.002,0,114.66,3.484,197.667,3.484c77.327,0,135-3.484,200.999-3.484C894.995,52,1200,52,1200,52V9z"/>
                </svg>
            </span>
        );
    }
}

export default withStyles(s)(Input);
