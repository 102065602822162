export const scrollTop = () => {
    var $toggleButton=$(".scrollButton");
    function openShareMenu(){
        TweenMax.to($toggleButton,0.1,{
            scaleX:1.2,
            scaleY:0.6,
            ease:Quad.easeOut,
            onComplete:function(){
                TweenMax.to($toggleButton,.8,{
                    scale:0.6,
                    ease:Elastic.easeOut,
                    easeParams:[1.1,0.6]
                })
            }
        })
    }
    function closeShareMenu(){
        TweenMax.to([$toggleButton],1.4,{
            delay:0.1,
            scale:1,
            ease:Elastic.easeOut,
            easeParams:[1.1,0.3]
        });
    }

    $toggleButton.on("mousedown",function(){
        openShareMenu();
    });
    $toggleButton.on("mouseup",function(){
        closeShareMenu();
    });


};