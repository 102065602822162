import React from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import footer from './Footer-top.css';
import icon from '../../../../fonts/ionicons.min.css';
import s from "../../../../style/page.css";
import f from '../../../../style/foundation.min.css';


const messages = defineMessages({
    footerDefaultValue: {
        id: 'footer.footerDefaultValue',
        defaultMessage: 'Theme',
    },
    footerDevelopment: {
        id: 'footer.footerDevelopment',
        defaultMessage: 'Website development',
    },
    footerDesign: {
        id: 'footer.footerDesign',
        defaultMessage: 'Design Services',
    },
    footerMarketing: {
        id: 'footer.footerMarketing',
        defaultMessage: 'Internet Marketing',
    },
    footerSupport: {
        id: 'footer.footerSupport',
        defaultMessage: 'Website support',
    }
});


class InputCheck extends React.Component {

    render() {
        const DefaultValue = this.props.intl.formatMessage({...messages.footerDefaultValue});
        const Development = this.props.intl.formatMessage({...messages.footerDevelopment});
        const Design = this.props.intl.formatMessage({...messages.footerDesign});
        const Marketing = this.props.intl.formatMessage({...messages.footerMarketing});
        const Support = this.props.intl.formatMessage({...messages.footerSupport});
        return(
            <input onClick={this.props.onclick} id={['themFooter']} value={(DefaultValue + ":") + " " + (this.props.vebSiteDevelopment == true ? [Development + ","] : '') + " " + (this.props.design == true ? [Design + ","] : '') + " " + (this.props.marketing == true ? [Marketing + ","] : '') + " " + (this.props.support == true ? [Support] : '')} className={footer['menu__label'] + " " + ['menu__label']} />
    );
    }
}

InputCheck.propTypes = {
    intl: intlShape.isRequired,
    onClick: PropTypes.func.isRequired,
    vebSiteDevelopment: PropTypes.bool.isRequired,
    design: PropTypes.bool.isRequired,
    marketing: PropTypes.bool.isRequired,
    support: PropTypes.bool.isRequired
}

export default injectIntl(
    compose(
        withStyles(s, f, icon, footer),
        connect(
            state => ({
            FOOTER: state.dataRu.FOOTER
        }),
        dispatch => ({})
)
)(InputCheck));