exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2dOWo,.rqJlG{position:fixed;display:inline-block;left:45px;bottom:5px;border-radius:50%;width:50px;height:50px;line-height:46px;margin-left:-40px;margin-top:-40px;background:transparent;text-align:center;color:#fff;border:none;outline:none;cursor:pointer}._2dOWo:hover,.rqJlG:hover{color:#fff}.rqJlG{font-size:30px;background:-webkit-gradient(linear,left top,right top,from(#ff5ca2),color-stop(#ef64b3),color-stop(#d86dc7),to(#a383f5));background:-webkit-linear-gradient(left,#ff5ca2,#ef64b3,#d86dc7,#a383f5);background:-o-linear-gradient(left,#ff5ca2,#ef64b3,#d86dc7,#a383f5);background:linear-gradient(90deg,#ff5ca2,#ef64b3,#d86dc7,#a383f5);-webkit-transform:scale(.95);-ms-transform:scale(.95);transform:scale(.95)}a.rqJlG{color:#fff}a.rqJlG:focus,a.rqJlG:hover{color:#dfd7d7}._2dOWo{z-index:9;background:-webkit-gradient(linear,left top,right top,from(#ff5ca2),color-stop(#ef64b3),color-stop(#d86dc7),to(#a383f5));background:-webkit-linear-gradient(left,#ff5ca2,#ef64b3,#d86dc7,#a383f5);background:-o-linear-gradient(left,#ff5ca2,#ef64b3,#d86dc7,#a383f5);background:linear-gradient(90deg,#ff5ca2,#ef64b3,#d86dc7,#a383f5);font-size:20px}._3NxU0{list-style-type:none;padding:0;margin:0}", ""]);

// exports
exports.locals = {
	"share-button": "rqJlG",
	"share-toggle-button": "_2dOWo",
	"share-items": "_3NxU0"
};