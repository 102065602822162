export const modalZ = () =>{
    var modalTopClick = document.getElementById( 'modalTopClick' ),
        modalTopPhone = document.getElementById( 'modalTopPhone' );




    function serviceModal() {
        var modModalTopPhone = new DialogFx( modalTopPhone );
        modalTopClick.addEventListener( 'click', modModalTopPhone.toggle.bind(modModalTopPhone));
    }



    setTimeout(function() {
        serviceModal();
    }, 1500);

};