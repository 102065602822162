import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Scroll-to-top.css';
import icon from '../../../../fonts/ionicons.min.css';
import { scrollTop } from '../Scripts/scripts';

class Button extends React.Component {
    render () {
        return (
            <button title='Back to top' className={s.scroll + " " + ['scrollButton']}
                    onClick={ () => { this.props.scrollToTop(); }}>
                <span className={s['arrow-up'] + " " + icon['ion-chevron-up']}></span>
            </button>
        )
    }
}

class ScrollButton extends React.Component {
    constructor() {
        super();

        this.state = {
            intervalId: 0
        };
        this.scrollToTop = this.scrollToTop.bind(this);
    }
    componentDidMount() {
        scrollTop();
    }
    scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }

    scrollToTop() {
        let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
        this.setState({ intervalId: intervalId });
    }
    render () {
        return (
            <Button
                scrollToTop={this.scrollToTop}
            />
        )
    }
}

export default withStyles(s, icon)(ScrollButton);
