export const mainMenu = () =>{
    if ($(window).width() >= 1100) {
        var objToStick = $("#header");
        var topOfObjToStick = $(objToStick).offset().top;

        console.log(topOfObjToStick);

        $(window).scroll(function () {
            var windowScroll = $(window).scrollTop();
            if (windowScroll > 200) {
                $(objToStick).addClass("topWindow");
            } else if (windowScroll <= topOfObjToStick) {
                $(objToStick).removeClass("topWindow");
            }
            ;
        });
        var stopOne;
        var stopTwo;
        var width;
        var stepRate = 15;
        var startPoint = 10;
        var endPoint = 100;
        var start = 1;
        var controlOne = 1;
        var controlTwo = 1;
        var stop = 1;
        var sway;
        var $path = $('svg path#menuSvg');

        function sizeSVG() {
            width = $(window).width();
            stopOne = width / 3;
            stopTwo = stopOne * 2;
            $('svg path#menuSvg').attr('d', 'M0,1 C' + stopOne + ',1 ' + stopTwo + ',1 ' + width + ',1 L' + width + ',0 L0,0 L0,1');
        }

        sizeSVG();

        $(window).resize(function () {
            sizeSVG();
        });
        $('#headerTop').hover(
            function () {
                cancelAnimationFrame(sway);
                function swayUp() {
                    if (start < endPoint) {
                        start = start + stepRate;
                    }
                    else if (controlOne < endPoint) {
                        controlOne = controlOne + stepRate;
                    }
                    else if (controlTwo < endPoint) {
                        controlTwo = controlTwo + stepRate;
                    }
                    else if (stop < endPoint) {
                        stop = stop + stepRate;
                    }
                    else {
                        return false;
                    }

                    $path.attr('d', 'M0,' + start + ' C' + stopOne + ',' + controlOne + ' ' + stopTwo + ',' + controlTwo + ' ' + $(window).width() + ',' + stop + ' L' + width + ',0 L0,0 L0,20');
                    sway = requestAnimationFrame(swayUp);
                }

                swayUp();

            },
            function () {
                cancelAnimationFrame(sway);

                var $path = $('svg path#menuSvg');

                function swayDown() {
                    if (start > startPoint) {
                        start = start - stepRate;
                    }
                    else if (controlOne > startPoint) {
                        controlOne = controlOne - stepRate;
                    }
                    else if (controlTwo > startPoint) {
                        controlTwo = controlTwo - stepRate;
                    }
                    else if (stop > startPoint) {
                        stop = stop - stepRate;
                    }
                    else {
                        clearInterval(sway);
                        return false;
                    }

                    $path.attr('d', 'M0,' + start + ' C' + stopOne + ',' + controlOne + ' ' + stopTwo + ',' + controlTwo + ' ' + $(window).width() + ',' + stop + ' L' + width + ',0 L0,0 L0,20');

                    sway = requestAnimationFrame(swayDown);

                }

                swayDown();
            }
        );

        (function () {
            var lastTime = 0;
            var vendors = ['ms', 'moz', 'webkit', 'o'];
            for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
                window.requestAnimationFrame = window[vendors[x] + 'RequestAnimationFrame'];
                window.cancelAnimationFrame = window[vendors[x] + 'CancelAnimationFrame']
                    || window[vendors[x] + 'CancelRequestAnimationFrame'];
            }

            if (!window.requestAnimationFrame)
                window.requestAnimationFrame = function (callback, element) {
                    var currTime = new Date().getTime();
                    var timeToCall = Math.max(0, 16 - (currTime - lastTime));
                    var id = window.setTimeout(function () {
                            callback(currTime + timeToCall);
                        },
                        timeToCall);
                    lastTime = currTime + timeToCall;
                    return id;
                };

            if (!window.cancelAnimationFrame)
                window.cancelAnimationFrame = function (id) {
                    clearTimeout(id);
                };
        }());
    }
    if ($(window).width() < 1100) {
        var objToStick = $("#header");
        var topOfObjToStick = $(objToStick).offset().top;

        console.log(topOfObjToStick);

        $(window).scroll(function () {
            var windowScroll = $(window).scrollTop();
            if (windowScroll > 200) {
                $(objToStick).addClass("topWindow");
            } else if (windowScroll <= topOfObjToStick) {
                $(objToStick).removeClass("topWindow");
            }
            ;
        });
        {
            setTimeout(() => document.body.classList.add('render'), 60);
            const navdemos = Array.from(document.querySelectorAll('nav.demos > .demo'));
            const total = navdemos.length;
            const current = navdemos.findIndex(el => el.classList.contains('demo--current'));
            const navigate = (linkEl) => {
            document.body.classList.remove('render');
            document.body.addEventListener('transitionend', () => window.location = linkEl.href);
        };
            navdemos.forEach(link => link.addEventListener('click', (ev) => {
                ev.preventDefault();
            navigate(ev.target);
        }));
            document.addEventListener('keydown', (ev) => {
                const keyCode = ev.keyCode || ev.which;
            let linkEl;
            if ( keyCode === 37 ) {
                linkEl = current > 0 ? navdemos[current-1] : navdemos[total-1];
            }
            else if ( keyCode === 39 ) {
                linkEl = current < total-1 ? navdemos[current+1] : navdemos[0];
            }
            else {
                return false;
            }
            navigate(linkEl);
        });
        }
        class ShapeOverlays {
            constructor(elm) {
                this.elm = elm;
                this.path = elm.querySelectorAll('path');
                this.numPoints = 10;
                this.duration = 900;
                this.delayPointsArray = [];
                this.delayPointsMax = 300;
                this.delayPerPath = 250;
                this.timeStart = Date.now();
                this.isOpened = false;
                this.isAnimating = false;
            }
            toggle() {
                this.isAnimating = true;
                for (var i = 0; i < this.numPoints; i++) {
                    this.delayPointsArray[i] = Math.random() * this.delayPointsMax;
                }
                if (this.isOpened === false) {
                    this.open();
                } else {
                    this.close();
                }
            }
            open() {
                this.isOpened = true;
                this.elm.classList.add('is-opened');
                this.timeStart = Date.now();
                this.renderLoop();
            }
            close() {
                this.isOpened = false;
                this.elm.classList.remove('is-opened');
                this.timeStart = Date.now();
                this.renderLoop();
            }
            updatePath(time) {
                const points = [];
                for (var i = 0; i < this.numPoints; i++) {
                    points[i] = (1 - ease.cubicInOut(Math.min(Math.max(time - this.delayPointsArray[i], 0) / this.duration, 1))) * 100
                }

                let str = '';
                str += (this.isOpened) ? `M 0 0 V ${points[0]}` : `M 0 ${points[0]}`;
                for (var i = 0; i < this.numPoints - 1; i++) {
                    const p = (i + 1) / (this.numPoints - 1) * 100;
                    const cp = p - (1 / (this.numPoints - 1) * 100) / 2;
                    str += `C ${cp} ${points[i]} ${cp} ${points[i + 1]} ${p} ${points[i + 1]} `;
                }
                str += (this.isOpened) ? `V 100 H 0` : `V 0 H 0`;
                return str;
            }
            render() {
                if (this.isOpened) {
                    for (var i = 0; i < this.path.length; i++) {
                        this.path[i].setAttribute('d', this.updatePath(Date.now() - (this.timeStart + this.delayPerPath * i)));
                    }
                } else {
                    for (var i = 0; i < this.path.length; i++) {
                        this.path[i].setAttribute('d', this.updatePath(Date.now() - (this.timeStart + this.delayPerPath * (this.path.length - i - 1))));
                    }
                }
            }
            renderLoop() {
                this.render();
                if (Date.now() - this.timeStart < this.duration + this.delayPerPath * (this.path.length - 1) + this.delayPointsMax) {
                    requestAnimationFrame(() => {
                        this.renderLoop();
                });
                }
                else {
                    this.isAnimating = false;
                }
            }
        }
        (function() {
            const elmHamburger = document.querySelector('.hamburger');
            const gNavItems = document.querySelectorAll('.global-menu__item');
            const elmOverlay = document.querySelector('.shape-overlays');
            const overlay = new ShapeOverlays(elmOverlay);

            elmHamburger.addEventListener('click', () => {
                if (overlay.isAnimating) {
                return false;
            }
            overlay.toggle();
            if (overlay.isOpened === true) {
                elmHamburger.classList.add('is-opened-navi');
                for (var i = 0; i < gNavItems.length; i++) {
                    gNavItems[i].classList.add('is-opened');
                }
            } else {
                elmHamburger.classList.remove('is-opened-navi');
                for (var i = 0; i < gNavItems.length; i++) {
                    gNavItems[i].classList.remove('is-opened');
                }
            }
        });

            $("#mobileMenu").on("click","a", function (event) {
                if (overlay.isAnimating) {
                    return false;
                }
                overlay.toggle();
                if (overlay.isOpened === true) {
                    elmHamburger.classList.add('is-opened-navi');
                    for (var i = 0; i < gNavItems.length; i++) {
                        gNavItems[i].classList.add('is-opened');
                    }
                } else {
                    elmHamburger.classList.remove('is-opened-navi');
                    for (var i = 0; i < gNavItems.length; i++) {
                        gNavItems[i].classList.remove('is-opened');
                    }
                }
                event.preventDefault();
                var id  = $(this).attr('href'),
                    top = $(id).offset().top;
                setTimeout(function() {
                    $('body,html').animate({scrollTop: top}, 1500);
                }, 1300);
                // $('body,html').animate({scrollTop: top}, 1500);
            });
        }());
        const ease = {
                exponentialIn: (t) => {
                return t == 0.0 ? t : Math.pow(2.0, 10.0 * (t - 1.0));
    },
        exponentialOut: (t) => {
            return t == 1.0 ? t : 1.0 - Math.pow(2.0, -10.0 * t);
        },
        exponentialInOut: (t) => {
            return t == 0.0 || t == 1.0
                ? t
                : t < 0.5
                    ? +0.5 * Math.pow(2.0, (20.0 * t) - 10.0)
                    : -0.5 * Math.pow(2.0, 10.0 - (t * 20.0)) + 1.0;
        },
        sineOut: (t) => {
            const HALF_PI = 1.5707963267948966;
            return Math.sin(t * HALF_PI);
        },
        circularInOut: (t) => {
            return t < 0.5
                ? 0.5 * (1.0 - Math.sqrt(1.0 - 4.0 * t * t))
                : 0.5 * (Math.sqrt((3.0 - 2.0 * t) * (2.0 * t - 1.0)) + 1.0);
        },
        cubicIn: (t) => {
            return t * t * t;
        },
        cubicOut: (t) => {
            const f = t - 1.0;
            return f * f * f + 1.0;
        },
        cubicInOut: (t) => {
            return t < 0.5
                ? 4.0 * t * t * t
                : 0.5 * Math.pow(2.0 * t - 2.0, 3.0) + 1.0;
        },
        quadraticOut: (t) => {
            return -t * (t - 2.0);
        },
        quarticOut: (t) => {
            return Math.pow(t - 1.0, 3.0) * (1.0 - t) + 1.0;
        },
    }
    }
}