import React from 'react';
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from './Checkbox.css';
import icon from '../../../fonts/ionicons.min.css';
class Checkbox extends React.Component {
    render() {
        return (
            <div className={s['input-container'] + " " + s['input-container--indent'] + " " + [this.props.class]}>
                <div className={s['input-wrap'] + " " + s['input-wrap--small']}>
                    <input checked={this.props.checked} onChange={this.props.onChange} value={this.props.value} ref={this.props.ref} type="checkbox" id={this.props.id} />
                    <i className={icon['ion-checkmark'] + " " + s['fa']}></i>
                    <span className={s['imorph-shape']} data-morph-active="M273,273c0,0-55.8,24-123,24c-78.2,0-123-24-123-24S3,235.3,3,150C3,79.936,27,27,27,27S72,3,150,3c85,0,123,24,123,24s24,38.43,24,123C297,229.646,273,273,273,273z">
								<svg width="100%" height="100%" viewBox="0 0 300 300" preserveAspectRatio="none">
									<path d="M273,273c0,0-55.8,0-123,0c-78.2,0-123,0-123,0s0-37.7,0-123c0-70.064,0-123,0-123s45,0,123,0c85,0,123,0,123,0s0,38.43,0,123C273,229.646,273,273,273,273z"/>
								</svg>
							</span>
                </div>
                <label className={s['input-label'] + " " + s['input-label--long']} for={this.props.id}>{this.props.text}</label>
            </div>
        );
    }
}

export default withStyles(s, icon)(Checkbox);
