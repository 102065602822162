export const Scripts = () =>{
    (function() {

        function SVGDDMenu( el, options ) {
            this.el = el;
            this.init();
        }

        SVGDDMenu.prototype.init = function() {
            this.shapeEl = this.el.querySelector( 'div.morph-shape' );

            var s = Snap( this.shapeEl.querySelector( 'svg' ) );
            this.pathEl = s.select( 'path' );
            this.paths = {
                reset : this.pathEl.attr( 'd' ),
                open : this.shapeEl.getAttribute( 'data-morph-open' )
            };

            this.isOpen = false;

            this.initEvents();
        };

        SVGDDMenu.prototype.initEvents = function() {
            this.shapeEl = this.el.querySelector( 'input.menu__label' ).addEventListener( 'click', this.toggle.bind(this) );


            // For Demo purposes only
            // [].slice.call( this.el.querySelectorAll('a') ).forEach( function(el) {
            //     el.onclick = function() { return false; }
            // } );
        };

        SVGDDMenu.prototype.toggle = function() {
            var self = this;

            if( this.isOpen ) {
                classie.remove( self.el, 'menu--open' );
                setTimeout(function() {
                    classie.remove( document.getElementsByClassName( 'menu__inner' )[0], 'openBlock' );
                }, 300);
            }
            else {
                classie.add( self.el, 'menu--open' );
                setTimeout(function() {
                    classie.add( document.getElementsByClassName( 'menu__inner' )[0], 'openBlock' );
                }, 300);
            }

            this.pathEl.stop().animate( { 'path' : this.paths.open }, 320, mina.easeinout, function() {
                self.pathEl.stop().animate( { 'path' : self.paths.reset }, 1000, mina.elastic );
            } );

            this.isOpen = !this.isOpen;
        };

        new SVGDDMenu( document.getElementById( 'dropdownMenu' ) );

    })();
    setTimeout(function() {
        if ($(window).width() >= 1100) {
            $('#mainpage').after('<span class="overlay">' +
                '<svg version="1.1" id="circleFooter" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 250" PreserveAspectRatio="none">' +
                    '<defs>' +
                        '<linearGradient id="mainGradient"><stop class="stop1" offset="5%" stop-color="#4648d0"></stop><stop class="stop2" offset="35%" stop-color="#6d2ad6"></stop><stop class="stop4" offset="65%" stop-color="#ab2dec"></stop><stop class="stop5" offset="95%" stop-color="#e834ca"></stop></linearGradient>' +
                '</defs>' +
                '<path d="M250,246.5c-97.85,0-186.344-40.044-250-104.633V250h500V141.867C436.344,206.456,347.85,246.5,250,246.5z" />' +
                '</svg>' +
                '</span>'
            );
        }
    }, 1000);
    if ($(window).width() >= 1100) {
        function inViewport($el) {
            var H = $(window).height(),
                r = $el[0].getBoundingClientRect(), t = r.top, b = r.bottom;
            return Math.max(0, t > 0 ? H - t : (b < H ? b : H));
        }

        $(window).on("scroll resize", function () {
            var window_offset = inViewport($('.homePage'));
            if ($(window).width() >= 1100) {
                $(".overlay").height(window_offset);
            }
            $(".caption").css("bottom", (window_offset / 4));
        })
    }
}