import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './Footer.css';
import FooterTop from './components/Footer-top/Footer-top';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';

class Footer extends React.Component {
    componentWillMount() {
        configureAnchors({scrollDuration: 2000})
    }
  render() {
      let mdate = new Date();
    return (
        <ScrollableAnchor id={'contactLink'}>
          <div id="footer" className={s.root}>
                <FooterTop />
                <div className={s.container}>
                  <span className={s.text}>© White Code {mdate.getFullYear()}</span>
                </div>
          </div>
        </ScrollableAnchor>
    );
  }
}

export default withStyles(s)(Footer);
