import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import footer from './Footer-top.css';
import icon from '../../../../fonts/ionicons.min.css';
import s from "../../../../style/page.css";
import f from '../../../../style/foundation.min.css';
import Input from '../../../Form/Input/Input-white';
import Textarea from '../../../Form/Textarea/Textarea-white';
import Checkbox from '../../../Form/Checkbox/Checkbox';
import { Scripts } from '../Scripts/scripts.js';
import InputCheck from './Input-Check.js';


const messages = defineMessages({
    footerSuccess1: {
        id: 'footer.footerSuccess1',
        defaultMessage: 'Thank you',
    },
    footerSuccess2: {
        id: 'footer.footerSuccess2',
        defaultMessage: 'Your message has been sent',
    },
    footerErr: {
        id: 'footer.footerErr',
        defaultMessage: 'There was an error sending the message',
    },
    footerTitle: {
        id: 'footer.footerTitle',
        defaultMessage: 'What kind of service are you interested in',
    },
    footerDefaultValue: {
        id: 'footer.footerDefaultValue',
        defaultMessage: 'Theme',
    },
    footerDevelopment: {
        id: 'footer.footerDevelopment',
        defaultMessage: 'Website development',
    },
    footerDesign: {
        id: 'footer.footerDesign',
        defaultMessage: 'Design Services',
    },
    footerMarketing: {
        id: 'footer.footerMarketing',
        defaultMessage: 'Internet Marketing',
    },
    footerSupport: {
        id: 'footer.footerSupport',
        defaultMessage: 'Website support',
    },
    footerName: {
        id: 'footer.footerName',
        defaultMessage: 'Name',
    },
    footerPhone: {
        id: 'footer.footerPhone',
        defaultMessage: 'Phone',
    },
    footerTextarea: {
        id: 'footer.footerTextarea',
        defaultMessage: 'Enter text',
    },
    footerAddFile: {
        id: 'footer.footerAddFile',
        defaultMessage: 'Add file',
    },
    footerSend: {
        id: 'footer.footerSend',
        defaultMessage: 'Send',
    },
    contactsFooterTitle1: {
        id: 'footer.contactsFooterTitle1',
        defaultMessage: 'UAE (International)',
    },
    contactsFooterText1: {
        id: 'footer.contactsFooterText1',
        defaultMessage: 'Dubai',
    },
    contactsFooterTitle2: {
        id: 'footer.contactsFooterTitle2',
        defaultMessage: 'Ukraine (Main)',
    },
    contactsFooterText2: {
        id: 'footer.contactsFooterText2',
        defaultMessage: 'Obuhov',
    },
    contactsFooterTitle3: {
        id: 'footer.contactsFooterTitle3',
        defaultMessage: 'Contact Information',
    },
    contactsFooterText3: {
        id: 'footer.contactsFooterText3',
        defaultMessage: '+38(044)531-66-65',
    },
    contactsFooterTitle4: {
        id: 'footer.contactsFooterTitle4',
        defaultMessage: 'We are working',
    },
    contactsFooterText4: {
        id: 'footer.contactsFooterText4',
        defaultMessage: 'Monday-Friday',
    }
});



class Footer extends React.Component {
    static contextTypes = {
        intl: intlShape,
    }
    constructor(props, context) {
        super(props);
        this.handelCheckbox = this.handelCheckbox.bind(this);
        this.handelThemChang = this.handelThemChang.bind(this);
        this.handelNameChang = this.handelNameChang.bind(this);
        this.handelPhoneChang = this.handelPhoneChang.bind(this);
        this.handelEmailChang = this.handelEmailChang.bind(this);
        this.handelTextChang = this.handelTextChang.bind(this);
        this.handelFileChang = this.handelFileChang.bind(this);
        this.handlerSubmit = this.handlerSubmit.bind(this);
        this.state = {
            checkboxGroup: {
                vebSiteDevelopment: false,
                design: false,
                marketing: false,
                support: false
            },
            them: '',
            name: '',
            phone: '',
            email: '',
            text: '',
            file: '',
            result: ''
        };
    }
    componentDidMount() {
        Scripts();
    }
    handelCheckbox(event) {
        let object = Object.assign(this.state.checkboxGroup)
        object[event.target.value] = event.target.value
        this.setState({checkboxGroup: object})
    }
    handleClickVebSiteDevelopment = event => {
        this.setState({ vebSiteDevelopment: event.target.checked })
        let object = Object.assign(this.state.checkboxGroup)
        object[event.target.value] = event.target.checked
        this.setState({checkboxGroup: object})
    };
    handleClickDesign = event => {
        this.setState({ design: event.target.checked })
        let object = Object.assign(this.state.checkboxGroup)
        object[event.target.value] = event.target.checked
        this.setState({checkboxGroup: object})
    };
    handleClickMarketing = event => {
        this.setState({ marketing: event.target.checked })
        let object = Object.assign(this.state.checkboxGroup)
        object[event.target.value] = event.target.checked
        this.setState({checkboxGroup: object})
    };
    handleClickSupport = event => {
        this.setState({ support: event.target.checked })
        let object = Object.assign(this.state.checkboxGroup)
        object[event.target.value] = event.target.checked
        this.setState({checkboxGroup: object})
    };
    handelThemChang(event) {
        this.setState({them: event.target.value})
    }
    handelNameChang(event) {
        this.setState({name: event.target.value})
    }
    handelPhoneChang(event) {
        this.setState({phone: event.target.value})
    }
    handelEmailChang(event) {
        this.setState({email: event.target.value})
    }
    handelTextChang(event) {
        this.setState({text: event.target.value})
    }
    handelFileChang(event) {
        this.setState({file: event.target.value})
    }
    handlerSubmit(event) {
        console.log("them: " + this.state.them);
        console.log("name: " + this.state.name);
        console.log("phone: " + this.state.phone);
        console.log("email: " + this.state.email);
        console.log("text: " + this.state.text);
        console.log("file: " + this.state.file);
        event.preventDefault();
        this.setState({
            them: '',
            name: '',
            phone: '',
            email: '',
            text: '',
            file: '',
            value: <FormattedMessage {...messages[this.props.FOOTER.title]} />
        });

        $.ajax({
            url: '/form-footer.php',
            type: 'POST',
            data: {
                "themFooter": this.state.them,
                "userNameFooter": this.state.name,
                "userPhoneFooter": this.state.phone,
                "userMailFooter": this.state.email,
                "userMessageFooter": this.state.text,
                "userFileFooter": this.state.file
            },
            cache: false,
            success: handelEmailChang => {
                this.setState({result: <FormattedMessage {...messages[this.props.FOOTER.success1]} /> + this.state.name + <FormattedMessage {...messages[this.props.FOOTER.success2]} />})
        },
            // Fail..
            error: handelEmailChang => {
                this.setState({result: <FormattedMessage {...messages[this.props.FOOTER.err]} />})
            }
        });
    }

    render() {
        let FOOTER = this.props.FOOTER;
        // const value = intl.formatMessage({id: FOOTER.defaultValue});
        return (
            <div id="contact" className={footer.FooterTop}>
                <h2 className={footer.titleBlock}><FormattedMessage {...messages[FOOTER.title]} />?</h2>
                <form onSubmit={this.handlerSubmit} className={footer.form + " " + f.row} id={['messageForm']}>
                    <div id="dropdownMenu" className={footer.menu + " " + ['menu']}>
                        <div className={footer['morph-shape'] + " " + ['morph-shape']} data-morph-open="M260,500H0c0,0,8-120,8-250C8,110,0,0,0,0h260c0,0-8,110-8,250C252,380,260,500,260,500z">
                            <svg width="100%" height="100%" viewBox="0 0 260 500" preserveAspectRatio="none">
                                <path fill="none" d="M260,500H0c0,0,0-120,0-250C0,110,0,0,0,0h260c0,0,0,110,0,250C260,380,260,500,260,500z"/>
                            </svg>
                        </div>
                    <InputCheck
                        onClick={this.handelThemChang}
                        vebSiteDevelopment={this.state.checkboxGroup.vebSiteDevelopment}
                        design={this.state.checkboxGroup.design}
                        marketing={this.state.checkboxGroup.marketing}
                        support={this.state.checkboxGroup.support}
                    />
                        <div className={footer['menu__inner'] + " " + ['menu__inner']}>
                            <Checkbox
                                id={'check1'}
                                checked={this.state.checkboxGroup.vebSiteDevelopment}
                                class={footer.footerCheck}
                                onChange={this.handleClickVebSiteDevelopment}
                                value={'vebSiteDevelopment'}
                                text={<FormattedMessage {...messages[FOOTER.development]} />}
                            />
                            <Checkbox
                                id={'check2'}
                                checked={this.state.checkboxGroup.design}
                                class={footer.footerCheck}
                                onChange={this.handleClickDesign}
                                value={'design'}
                                text={<FormattedMessage {...messages[FOOTER.design]} />}
                            />
                            <Checkbox
                                id={'check3'}
                                checked={this.state.checkboxGroup.marketing}
                                onChange={this.handleClickMarketing}
                                class={footer.footerCheck}
                                value={'marketing'}
                                text={<FormattedMessage {...messages[FOOTER.marketing]} />}
                            />
                            <Checkbox
                                id={'check4'}
                                checked={this.state.checkboxGroup.support}
                                onChange={this.handleClickSupport}
                                class={footer.footerCheck}
                                value={'support'}
                                text={<FormattedMessage {...messages[FOOTER.support]} />}
                            />
                        </div>
                    </div>
                    <div className={f['large-5'] + " " + f['columns'] + " " + footer['columns']}>
                        <Input
                            type={'text'}
                            inputId={'userNameFooter'}
                            inputText={<FormattedMessage {...messages[FOOTER.name]} />}
                            value={this.state.name}
                            onChange={this.handelNameChang}
                        />
                        <Input
                            type={'text'}
                            inputId={'userPhoneFooter'}
                            inputText={<FormattedMessage {...messages[FOOTER.phone]} />}
                            value={this.state.phone}
                            onChange={this.handelPhoneChang}
                        />
                        <Input
                            type={'text'}
                            inputId={'userMailFooter'}
                            inputText={FOOTER.email}
                            value={this.state.email}
                            onChange={this.handelEmailChang}
                        />
                    </div>
                    <div className={f['large-7'] + " " + f['columns'] + " " + footer['columns']}>
                        <Textarea
                            type={'text'}
                            textareaId={'userMessageFooter'}
                            inputText={<FormattedMessage {...messages[FOOTER.textarea]} />}
                            viewBox={'0 7 1200 54.4'}
                            textareaClass={footer.inputTextarea}
                            value={this.state.text}
                            onChange={this.handelTextChang}
                        />
                    </div>
                    <div className={footer['form-group'] + " " + f['large-8'] + " " + f['columns'] + " " + footer['columns']}>
                        <label className={footer.label}>
                            <p className={footer.title}><em className={icon['ion-plus']}></em> &nbsp;{<FormattedMessage {...messages[FOOTER.addFile]} />} {(this.state.file == '' ? "" : <em className={icon['ion-document-text'] + " " + footer.fileTrue}><span>{this.state.file.split("/").pop().split(".").pop()}</span></em>)}</p>
                            <p>{this.state.file.replace(/^.*[\\\/]/, '')}</p>
                            <input
                                type='file'
                                value={this.state.file}
                                onChange={this.handelFileChang}
                            />
                        </label>
                    </div>
                    <div className={footer['form-group'] + " " + f['large-4'] + " " + f['columns'] + " " + footer['columns']}>
                        <button className={footer.buttonSubscribe} id={'sendFooter'} type="submit" value="Submit">
                            <span className={footer.title}>{<FormattedMessage {...messages[FOOTER.button]} />}</span>
                        </button>
                    </div>
                    <div id={footer.loadBarFooter}>{this.state.result}</div>
                </form>
                <div className={s.row + " " + footer.contact}>
                    {FOOTER.CONTACTSFOOTER.map((item, index) => {
                        return (
                            <span key={index}>
                                <img src={item.src} alt={item.title} />
                                <h2>{<FormattedMessage {...messages[item.title]} />}</h2>
                                <p>{item.textBr},<br/> {<FormattedMessage {...messages[item.text]} />}</p>
                            </span>
                        )
                    })}
                </div>
            </div>
        );
    }
}






export default injectIntl(
    compose(
        withStyles(s, f, icon, footer),
        connect(
            state => ({
            FOOTER: state.dataRu.FOOTER
        }),
        dispatch => ({})
)
)(Footer));
