exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ":root{--font-family-base:\"Segoe UI\",\"HelveticaNeue-Light\",sans-serif;--max-content-width:1000px;--screen-xs-min:480px;--screen-sm-min:768px;--screen-md-min:992px;--screen-lg-min:1200px}._1UUMy{position:relative;background:-webkit-gradient(linear,left top,right top,from(#4648d0),color-stop(#6d2ad6),color-stop(#ab2dec),to(#e834ca));background:-webkit-linear-gradient(left,#4648d0,#6d2ad6,#ab2dec,#e834ca);background:-o-linear-gradient(left,#4648d0,#6d2ad6,#ab2dec,#e834ca);background:linear-gradient(90deg,#4648d0,#6d2ad6,#ab2dec,#e834ca);padding:30px 0;z-index:1}._1UUMy ._3dfAC{position:absolute;bottom:0;width:100%;background:-webkit-gradient(linear,left top,right top,from(#282e66),color-stop(#384091),color-stop(#4f5bce),to(#6070f9));background:-webkit-linear-gradient(left,#282e66,#384091,#4f5bce,#6070f9);background:-o-linear-gradient(left,#282e66,#384091,#4f5bce,#6070f9);background:linear-gradient(90deg,#282e66,#384091,#4f5bce,#6070f9);color:#fff;margin:0 auto;padding:20px 15px;text-align:center}._1UUMy ._3dfAC ._3qHjF,._1UUMy ._3dfAC ._3ReUN{padding:2px 15px;font-size:14px}._1UUMy ._3dfAC ._3qHjF,._1UUMy ._3dfAC ._3qHjF:active,._1UUMy ._3dfAC ._3qHjF:visited{text-decoration:none}._1UUMy ._3dfAC ._3qHjF:hover{opacity:.5;-webkit-transition:all .2ms;-o-transition:.2ms all;transition:all .2ms}", ""]);

// exports
exports.locals = {
	"root": "_1UUMy",
	"container": "_3dfAC",
	"text": "_3ReUN",
	"link": "_3qHjF"
};