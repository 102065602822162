/* eslint-disable no-shadow */

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { setLocale } from '../../actions/intl';
import { languages } from './scripts/scripts';
import s from './LanguageSwitcher.css';
import icon from '../../fonts/ionicons.min.css';

const localeDict = {
  /* @intl-code-template '${lang}-${COUNTRY}': '${Name}', */
  'en-US': 'En',
  'ru-RU': 'Ru',
    'uk-UA': 'Ua',
  /* @intl-code-template-end */
};

class LanguageSwitcher extends React.Component {
    static propTypes = {
        currentLocale: PropTypes.string.isRequired,
        availableLocales: PropTypes.arrayOf(PropTypes.string).isRequired,
        setLocale: PropTypes.func.isRequired,
    };
    componentDidMount(){
        languages();
    }
    render() {
        const { currentLocale, availableLocales, setLocale } = this.props;
        const isSelected = locale => locale === currentLocale;
        const localeName = locale => localeDict[locale] || locale;
        return (
            <div className={s['share'] + " " + ['share']}>
              <button className={s['share-toggle-button'] + " " + ['share-toggle-button']}>
            {console.log(currentLocale)}

                <i>{(currentLocale === 'en-US') ? 'En' : (currentLocale === 'ru-RU') ? 'Ru' : (currentLocale === 'uk-UA') ? 'Ua' : ''}</i>
              </button>
              <ul className={s['share-items'] + " " + ['share-items']}>
                  {availableLocales.map(locale => (
                      <li key={locale} className={s['share-item'] + " " + ['share-item']}>
                          {isSelected(locale) ? (
                          <a href="/" className={s['share-button'] + " " + ['share-button']}>
                            <span>{localeName(locale)}</span>
                          </a>
                          ) : (
                          <a
                              href={`${locale}`}
                              onClick={e => {
                                  setLocale({ locale });
                                  e.preventDefault();
                              }}
                              className={s['share-button'] + " " + ['share-button']}>
                            <span>{localeName(locale)}</span>
                          </a>
                          )}{' '}
                      </li>
                  ))}
              </ul>
            </div>
        );
    }
}
const mapState = state => ({
  availableLocales: state.runtime.availableLocales,
  currentLocale: state.intl.locale,
});

const mapDispatch = {
  setLocale,
};


export default injectIntl(
  compose(
    withStyles(s, icon),
    connect(mapState, mapDispatch)
  )(LanguageSwitcher));
